@use "./../styles/mixins.scss" as mixins;

.app-nav {
  --app-nav-headerHeight: 6rem;
  --app-nav-subHeaderHeight: 6rem;
  --app-nav-rowHeight: 34px;
  --app-nav-rowComponentHeight: var(--app-nav-rowHeight);

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .app-nav-header {
    height: 6rem;
    width: 100%;
    flex: 0 0 auto;
    border-bottom: 1px solid var(--app-color-grey);
    justify-content: center;
  }

  .app-nav-header {
    padding: 1.2rem 2rem;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    @include mixins.is-mobile {
      padding: 12px;

      .app-nav-row:first-child {
        padding-left: 40px;
      }
    }

    .app-nav-row {
      width: 100%;
      min-height: var(--app-nav-rowHeight);
      position: relative;
      display: flex;
      justify-content: space-between;
      align-items: center;

      --app-formItem-height: var(--app-nav-rowHeight);

      &.-grid {
        @include mixins.grid;
      }
      &.-mobileRow {
        @extend .-mobile-only;
        margin-top: 15px;

        week-selector {
          width: 100%;
        }
      }

      .app-nav-mobileBack {
        display: none;
        margin-right: 0;

        @include mixins.is-mobile {
          display: block;
          margin-right: 10px;
          margin-left: -40px;
        }
      }

      .app-nav-rowFull {
        width: 100%;
        justify-content: flex-start;
        flex-wrap: nowrap;
        flex: 0 0 auto;
        align-items: center;
        overflow: hidden;
      }
      .app-nav-rowLeft {
        justify-content: flex-start;
        flex-wrap: wrap;
        flex: 0 1 auto;
        align-items: center;
        margin-top: -10px;

        > * {
          margin-top: 10px;
          margin-right: 15px;
        }
      }
      .app-nav-rowRight {
        justify-content: flex-end;
        flex-wrap: nowrap;
        flex: 0 0 auto;
        margin-top: -10px;
        margin-left: -10px;

        > * {
          margin-top: 10px;
          margin-left: 15px;
        }
        > button {
          flex: 0 0 auto;
        }
      }
      .app-nav-rowLeft,
      .app-nav-rowRight,
      .app-nav-rowFull {
        min-height: var(--app-nav-rowHeight);
        display: flex;
        position: relative;

        .app-nav-title {
          font-size: 1.4rem;
          font-weight: 500;
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1 0 auto;

          .app-nav-titleInner {
            display: flex;
            align-items: center;
          }

          .app-nav-titleCompanyLogo {
            height: 1.5rem;
            margin-right: 10px;
          }
          .app-nav-titleDescription {
            font-size: 1rem;
            color: var(--app-color-grey-very-dark);
            font-weight: 400;
            max-width: 12rem;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;
          }
          input.app-nav-titleInput {
            font-size: 1.2rem;
            font-weight: 400;
            height: var(--app-nav-rowComponentHeight);
            line-height: var(--app-nav-rowComponentHeight);
            background-color: var(--app-color-white);
            border: var(--app-border);
            border-radius: var(--app-border-radius);
            padding: 0 12px;
            width: 100%;
          }
          button.app-nav-previousPage {
            @extend .-color-payhero-clear;
            --button-height: 1.5rem;
            --button-fontSize: 1rem;
            --button-iconSize: 1rem;
            font-weight: 600;
            justify-content: flex-start;
            margin-bottom: 5px;
          }
          > span {
            display: block;
          }
        }

        list-drop-down {
          --listDropDown-button-size: var(--app-nav-rowComponentHeight);
        }
        date-picker {
          --datePicker-height: var(--app-nav-rowComponentHeight);
          width: 180px;
        }
        > button {
          --button-height: var(--app-nav-rowComponentHeight);
        }
        .app-nav-formSpinner {
          position: relative;
          margin-right: 15px;
          transform: scale(0.75);
        }
        .ph-formItem {
          margin-bottom: 0;
          height: var(--app-nav-rowComponentHeight);

          .ph-formItem-input {
            height: var(--app-nav-rowComponentHeight);
            line-height: 39px;
          }
        }
        super-search {
          height: 34px;
        }
        week-selector {
          --weekSelector-height: 34px;
        }
      }

      @include mixins.is-mobile {
        .app-nav-rowLeft {
          min-width: 0;
          flex-wrap: nowrap;

          > .app-nav-title {
            min-width: 0;
          }
          > * {
            margin-right: 10px;
          }
        }
        .app-nav-rowRight {
          button {
            flex: 0 0 auto;
          }
          > * {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &.-tabNav {
    --app-nav-headerHeight: 110px;

    .app-nav-header {
      padding-bottom: 0;

      .app-nav-row.-tabRow {
        --app-nav-rowHeight: 50px;
        @include mixins.grid;
        margin-top: 5px;
        display: flex;

        .app-nav-rowRight,
        .app-nav-rowLeft {
          margin-top: 0;

          > * {
            margin-top: 0;
          }
        }
        .app-nav-rowRight {
          align-items: center;
        }
        .app-nav-rowLeft {
          align-items: stretch;
          align-self: flex-end;

          .app-nav-tab {
            padding: 0 15px;
            margin-right: 7px;
            text-align: center;
            position: relative;
            cursor: pointer;
            transition: background-color 0.25s;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              background-color: rgb(17, 139, 224, 0.05);
            }

            .app-nav-tabBackIcon {
              height: var(--app-nav-rowHeight);
              line-height: var(--app-nav-rowHeight);
              margin: 0 10px;
              font-size: 1.3rem;
              float: left;
            }
            span {
              float: left;
            }
            &.-activeTab {
              font-weight: 500;
            }
            .-tab-underline {
              width: 100%;
              height: 2px;
              border-radius: 5px;
              background-color: var(--app-color-link);
              position: absolute;
              bottom: 0;
              left: 0;
              right: 0;
            }
          }
        }
      }
    }
  }

  .app-nav-content {
    width: 100%;
    height: calc(100% - var(--app-nav-headerHeight));
    background-color: var(--app-color-grey-very-light);
    overflow-y: scroll;
    overflow-y: overlay;
    position: relative;
    flex: 1 1 auto;

    .dp-window {
      width: 100%;
      max-width: var(--app-content-width);
      background-color: var(--app-color-white);
      padding: var(--app-content-padding);
      margin: 0 auto;
      display: block;
      min-height: 100%;

      // --app-nav-width + --app-content-width
      @media (min-width: calc(240px + 1300px)) {
        border-left: var(--app-border);
        border-right: var(--app-border);
      }

      &.-horizontalScroll {
        padding: 0;
        position: relative;
        width: auto;
        height: auto;
        overflow-x: auto;
      }

      .dp-toolbar {
        width: 100%;
        height: 50px;
        margin-bottom: 15px;

        > button {
          margin: 8px 0;
        }
      }
    }
  }
}
