@use "./mixins.scss" as mixins;

body {
  .app-page,
  .token-page {
    min-height: 100%;
    display: block;
    position: relative;
    overflow: auto;
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;

    @include mixins.is-mobile {
      padding: 0 25px 25px 25px;
    }
  }

  .token-page {
    padding-left: 0 !important;
    padding-right: 0 !important;

    text-align: left;
    display: block;

    .token-page-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 60px 0px 20px 0px;

      .token-page-header-logo {
        height: 60px;
        max-height: 60px;

        & + .token-page-header-title {
          margin-top: 20px;
        }
      }

      .token-page-header-title {
        font-size: 30px;
        color: var(--app-color-white);
      }
    }
  }

  .app-page {
    @include is-mobile {
      overflow-x: clip;

      .integrationButton-outer {
        --heightPadding: 14px;
        padding-top: var(--heightPadding) !important;
        padding-bottom: var(--heightPadding) !important;
      }
      .app-auth-card {
        &.-signup-or-login-page {
          .app-integrationsDivider {
            padding-top: 15px;
            padding-bottom: 15px;
          }
        }
      }
      @media (max-height: 700px) {
        .app-auth-logo {
          &.-externalPage {
            margin-top: 20px;
          }
        }
        .app-auth-pageTitleContainer {
          margin-top: 30px;

          &.-justTitle,
          &.-titleAndDesc,
          &.-expandTitle {
            margin-top: 20px;
            margin-bottom: 10px;
          }
        }
        .app-auth-card {
          &.-signup-or-login-page {
            .fieldLabel {
              &:not(:first-child) {
                margin-top: 20px;
              }
            }
          }
        }
      }
    }
  }

  .app-auth-contentContainer {
    overflow-x: clip;
  }

  &.-theme-droppah {
    .app-page {
      background-color: var(--droppah-color-ruby);
    }

    app-signup {
      background-color: var(--droppah-color-emerald) !important;
      .app-auth-submit {
        background-color: var(--droppah-color-mint) !important;
        color: var(--app-color-black) !important;
        &:disabled {
          opacity: 0.5;
        }
      }
    }

    .app-page,
    .token-page {
      height: 100%;
      width: 100%;
      max-width: 100vw;
      overflow-x: hidden;
      --app-focus: var(--app-focus-droppah);

      font-family: "Sharp Grotesk", Helvetica, sans-serif;
      font-weight: 200 !important;
      font-size: medium;

      .app-auth-logo {
        position: absolute;
        top: -20px;
        right: 30px;
        width: 300px;
        @media only screen and (max-width: 1400px) {
          display: none;
        }
      }
      .app-header-byFlexitime {
        img {
          max-width: 110px;
          margin: 40px 0 20px 0;
        }
      }
      .app-footer-droppah {
        margin-top: 30px;
        text-align: left;
        app-footer-user-agreements {
          justify-content: left;
        }
      }
      .app-footer-byFlexitime {
        img {
          margin-bottom: -20px;
          min-height: 0px !important;
          width: 170px;
        }
      }
      .app-auth-pageTitle {
        color: var(--app-color-white);
      }

      .app-auth-full-container {
        height: 100% !important;
        width: 100% !important;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 0px;
      }

      .app-auth-pageTitleContainer {
        color: var(--app-color-white);
      }

      .app-auth-2fa-rememberMe {
        color: var(--app-color-white);
        justify-content: start;
      }
      .app-auth-integration-container {
        display: flex;
        justify-content: space-between;
        integration-button {
          width: calc(50% - 10px);
        }
      }

      .app-auth-card {
        width: 60%;
        max-width: 800px !important;
        justify-content: flex-start;
        .app-infoPanel {
          border-radius: var(--droppah-border-radius);
          background-color: var(--droppah-color-mint);
          color: var(--theme-color-background-0);
          a {
            color: var(--theme-color-background-0);
          }
          margin-bottom: 50px;
        }
        .app-auth-logo-droppah {
          display: none;
          width: 400px;
          max-width: 100% !important;
          min-height: 0px !important;
          margin-bottom: 30px;
          @media only screen and (max-width: 1400px) {
            display: block;
          }
        }
      }

      .app-auth-contentContainer {
        @media only screen and (min-width: 1400px) {
          padding: 0px 100px 0px 100px;
        }
        --product-edge-overlap: 50px;
        justify-content: center;
        width: 100%;

        .-productGraphic {
          width: 40%;
          margin-left: 100px;
          @media only screen and (max-width: 1400px) {
            display: none;
          }
          > img {
            width: 100%;
          }
        }
      }

      .app-auth-droppah-mobile-graphic {
        display: none;
        display: none;
        justify-content: center;
        width: 100%;
        img {
          margin-bottom: 30px;
          width: 200px;
          min-width: 0px !important;
          min-height: 0px !important;
          @media screen and (max-height: 750px) {
            width: 150px;
          }
        }
        @include is-mobile {
          display: flex;
        }

        @media screen and (min-height: 900px) {
          display: none !important;
        }

        @media screen and (max-height: 660px) {
          display: none !important;
        }
      }

      .app-auth-droppah-content {
        margin-bottom: 30px;

        h1 {
          text-align: left;
          color: var(--app-color-white);
          font-size: 48px;
          line-height: 60px;
          @media screen and (min-width: 1000px) {
            font-size: 56px;
            line-height: 70px;
          }
          @media screen and (max-width: 1000px) {
            font-size: 32px;
            line-height: 42px;
          }
        }
        h4 {
          color: var(--app-color-white);
          font-size: 24px;
          line-height: 30px;
          font-weight: 200;
          margin-top: 20px;
          text-align: left;
        }
        &.-hide-on-mobile {
          @media screen and (max-height: 900px) {
            display: none;
          }
        }

        @media screen and (max-height: 1000px) and (max-width: 1400px) {
          h1 {
            font-size: 34px !important;
            line-height: 42px !important;
          }
          h4 {
            font-size: 18px;
            line-height: 26px;
          }
        }
      }
      .app-auth-card {
        border-radius: 0;
        padding: 0px;
        overflow: visible;
        max-width: 500px;
        box-shadow: none;
        text-align: left;
        input {
          border-radius: 1000px;
          border: none;
          height: 55px;
          font-weight: 200;
        }

        .app-auth-errorMessage {
          border-radius: 50px;
          font-size: 14px;

          &:not(.-hidden) {
            padding: 15px 10px;
            margin-bottom: 25px;
          }

          > i {
            display: none;
          }
        }

        .app-auth-card-inner {
          padding: 0;

          .app-auth-textLink,
          .app-auth-textLink > a {
            text-align: left;
            color: var(--app-color-white) !important;
            font-weight: 200;

            @media screen and (max-width: 1000px) {
              font-size: 12px !important;
            }

            &:not(:hover) {
              text-decoration: none;
            }
          }

          .app-auth-form {
            .app-auth-submit {
              border-radius: 1000px;
              background-color: var(--app-color-droppah);
              color: var(--app-color-white);
              height: 55px;
              font-weight: 500;
              width: 200px;
            }

            .-showPasswordIcon i {
              font-size: x-large;
            }

            #password,
            #username {
              margin-bottom: 20px;
            }
            .fieldLabel,
            .app-auth-inputLabel {
              color: var(--app-color-white);
              font-weight: 500;

              .app-auth-forgot {
                color: var(--app-color-white);
                &:hover {
                  text-decoration: underline;
                }
              }
            }
          }

          .app-integrationsDivider {
            color: var(--app-color-white);
            display: flex;
            align-items: center;
            padding-left: 0;
            padding-right: 0;
            hr {
              color: var(--app-color-white);
              flex: auto;
              opacity: 1;
            }

            > div {
              margin: 0 10px 0 10px;
            }
          }

          .app-auth-textLink > a {
            font-weight: 500;
          }
        }
        @media screen and (max-height: 800px) {
          margin-top: -40px;
          .app-auth-card-inner {
            .app-input,
            .app-auth-submit {
              font-size: 12px;
              height: 40px !important;
            }
            .app-auth-textLink,
            .app-auth-textLink > a,
            app-footer-user-agreements {
              font-size: 10px;
              margin-top: 10px;
            }
            .app-integrationsDivider {
              padding: 5px 0px;
            }
            .app-footer-byFlexitime {
              margin: 10px 0px;
            }
          }
        }
        @media screen and (max-width: 500px) {
          .app-auth-integration-container {
            flex-direction: column;
            integration-button {
              width: 100%;
            }
          }
        }
      }
      .app-footer-byFlexitime {
        margin: 20px 0px;
      }

      app-footer-user-agreements {
        margin-bottom: 0;
      }
      .app-footer-userAgreement,
      .-spacer {
        text-decoration: none;
        font-size: 10px !important;
        font-weight: 500;
        color: var(--app-color-white) !important;
        opacity: 0.7;
      }

      @include is-mobile {
        font-size: 12px !important;

        // --heightPadding: 14px;
        --inputHeight: 46px;

        .app-auth-logo {
          padding: 0;
          width: calc(100% - 20px);
        }
        .app-auth-card {
          margin-top: 0px;
          width: 500px;
          max-width: calc(100vw - 40px);
        }
        .app-auth-pageTitleContainer {
          margin-top: 10px;
          width: 100%;
          &.-expandTitle,
          &.-noTitle {
            margin-top: 0;
            height: unset;
          }
        }

        .app-auth-contentContainer {
          bottom: 0px;
        }

        .app-integrationsDivider {
          padding: 0 !important;
          margin: 20px 0px !important;
        }
        .fieldLabel:not(.-mobile-only) {
          display: none;
        }
        .fieldLabel {
          margin-top: 10px !important;
        }
        .app-auth-forgot {
          position: relative !important;
          justify-content: center;
        }
        .app-input {
          padding: 0 20px;
        }

        .app-auth-submit {
          height: var(--inputHeight) !important;
          margin-top: 15px !important;
          width: 100% !important;
        }
        app-footer-user-agreements {
          margin-top: auto;
          padding-top: 20px;
        }
        .app-footer-byFlexitime {
          margin: 15px 0 10px;
        }

        //quick fix after release to prod had issues in mobile 9/2/23
        .app-auth-full-container {
          flex-direction: column;
          // justify-content: end;
          margin-bottom: 20px;

          .app-auth-logo {
            padding-top: 10px;
            margin-top: auto;
            margin-bottom: auto;
            max-height: 85px;
          }

          .app-auth-pageTitleContainer {
            position: relative;
            bottom: 0;
            margin-bottom: 30px;
          }

          .app-auth-contentContainer {
            margin-left: 0;
            position: relative;
          }
        }
        //
      }
    }
  }
}
