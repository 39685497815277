// Needs to be loaded first for bootstrap variable overrides to work
@use '../node_modules/bootstrap-icons/font/bootstrap-icons.css';
@use '../node_modules/bootstrap/scss/bootstrap.scss';

@use 'styles/mixins.scss' as mixins;
@import 'styles/variables.scss';
@import 'styles/classModifiers.scss';
@import 'styles/theme.scss';
@import 'styles/bootstrapOverrides.scss';
@import 'styles/modals.scss';
@import 'styles/icons.scss';
@import 'styles/buttons.scss';
@import 'styles/ph-auth.scss';
@import 'styles/ph-formItem.scss';
@import 'styles/app-search.scss';
@import 'styles/app-auth.scss';
@import 'styles/app-input.scss';
@import 'styles/app-nav.scss';
@import 'styles/app-footer.scss';
@import 'styles/app-approvalProject.scss';
@import 'styles/app-page.scss';

html {
  height: 100%;
  width: 100%;
  font-size: 0.9rem;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Work Sans', sans-serif;

  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
  color: var(--app-color-dark);
  font-family: 'Work Sans' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  *:focus {
    outline: none !important;
  }
  padding-bottom: env(safe-area-inset-bottom);
}

ui-view {
  width: 100%;
  height: 100%;
  display: block;
}

:root {
  min-height: 100%;
  width: 100%;
  position: relative;

  ::-webkit-scrollbar {
    width: 7px;
    background: #f9f9f9;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #dddddd;
    border-radius: 0 !important;
  }

  // Hide number input arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input,
  select,
  textarea {
    transition: all 0.25s;

    &:focus {
      box-shadow: var(--app-focus);
    }
  }

  textarea {
    width: 100%;
    padding: 0 12px;
    background-color: transparent;
    border: var(--app-border);
    border-radius: 5px;
    transition: all 0.25s;

    &::placeholder {
      color: var(--app-color-grey-dark);
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin: 5px 0;
  }

  h1 {
    font-size: 32px;
    line-height: 42px;
  }

  h2 {
    font-size: 21px;
    line-height: 34px;
  }

  h3 {
    font-size: 18px;
    line-height: 28px;
  }

  h4 {
    font-size: 14px;
    line-height: 22px;
  }

  h5 {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.9rem;
    line-height: 26px;
    font-weight: 400;
  }

  .pull-left {
    float: left;
  }

  .pull-right {
    float: right;
  }

  .ph-footer {
    height: 50px;
    padding: 4px 0;
    background-color: #ebebeb;
    display: -webkit-box;
    width: 100%;
    transition: all 0.25s;

    img {
      width: 120px;
      display: block;
      margin: 0 auto;
    }
  }

  .ph-loadingIcon {
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    height: 40px;
    width: 40px;
    z-index: 100;

    img {
      height: 40px;
      width: 40px;
    }
  }

  a {
    &.supportLink {
      font-weight: 400;

      &.offset {
        display: block;
        margin-top: 20px;
      }

      &.inCol {
        display: block;
        margin-top: 5px;
      }

      > i {
        top: 0;
        margin-left: 3px;
      }
    }
  }

  a,
  a:not([href]):not([tabindex]),
  .btn-link {
    color: var(--theme-link-color);

    &:hover {
      color: var(--theme-link-hover-color);
      text-decoration: none;
      cursor: pointer;
    }

    &:focus {
      outline: 0;
    }

    &.danger {
      color: #d9534f;

      &:hover {
        color: var(--app-color-danger);
      }

      &:focus {
        color: #a32f2f;
      }
    }

    &.grey {
      color: var(--app-color-grey-dark);

      &:hover {
        color: var(--app-color-grey-dark);
      }

      &:focus {
        color: var(--app-color-grey-dark);
      }
    }

    &.btn-xero-fix {
      padding: 0;
      border: 0;
      line-height: 1;
      top: -1px;
    }
  }

  .dp-loadingOverlay {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--app-color-white);
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    pointer-events: none;

    spinner {
      opacity: 1;
    }
  }

  .app-errorMessage {
    height: 0;
    width: 100%;
    padding: 0 10px;
    text-align: center;
    color: var(--app-color-white);
    line-height: 1.5;
    opacity: 0;
    background-color: var(--app-error-background);
    border: 1px solid var(--app-error-border);
    border-radius: var(--app-border-radius);
    margin: 0;
    transition: height var(--app-transition), margin var(--app-transition),
      padding var(--app-transition), opacity var(--app-transition);

    i {
      position: relative;
      top: 1px;
      margin-right: 5px;
    }

    &.-visible {
      height: auto;
      margin-bottom: 10px;
      padding: 10px;
      opacity: 1;
    }
  }

  img.app-splash-logo {
    height: 80px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    @include mixins.is-mobile {
      max-width: calc(100% - 60px);
    }
  }

  .app-splash-spinner {
    top: auto !important;
    bottom: 100px !important;
  }

  .app-buttonRow {
    width: 100%;
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    height: 34px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .app-dashListDivider {
    background-color: var(--app-color-grey-very-light);
    height: 1rem;
    border-top: var(--app-border-light);
    border-bottom: var(--app-border-light);
  }

  .dp-remove,
  .dp-add {
    flex: 0 0 auto;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-left: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--app-color-white);
    cursor: pointer;
    transition: background-color var(--app-transition);

    &:hover {
      background-color: var(--app-color-grey-light);
    }
  }

  .app-integrationsDivider {
    position: relative;
    padding: 30px 10px;

    .app-integrationsDivider-line {
      height: 1px;
      background-color: var(--app-color-border);
      opacity: 1;
      width: 100%;
      margin: 0;
    }

    .app-integrationsDivider-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      color: var(--app-color-grey-dark);
      font-weight: 400;
      font-size: 0.9rem;
      background-color: #fff;
      padding: 0 15px;
    }
  }

  .app-fieldOptional {
    margin-left: 5px;
    font-size: 0.8rem;
    font-style: italic;
  }

  // Google Address Autocomplete Dropdown
  .pac-container {
    z-index: 1100;
  }

  img.app-integrationButton {
    cursor: pointer;
    display: block;
    height: 50px;

    &:hover {
      opacity: 0.8;
    }
  }

  .ph-info-modal .modal-dialog {
    max-width: 450px;
  }
}

.app-infoPanel {
  background-color: var(--app-info-background);
  border: 1px solid var(--app-info-border);
  border-radius: var(--app-border-radius);
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.-warningPanel {
    background-color: var(--app-warning-background);
    border-color: var(--app-warning-border);
  }

  &.-errorPanel {
    background-color: var(--app-error-background);
    border-color: var(--app-error-border);
  }

  &.-successPanel {
    background-color: var(--app-color-success-translucent);
    border-color: var(--app-color-success);
  }

  .app-infoPanel-row {
    text-align: center;
    width: 100%;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    > span {
      display: contents;
    }
  }
}

.-lineThrough {
  text-decoration: line-through;
}
