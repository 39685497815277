.app-input {
  --app-input-height: 34px;
  --app-input-line-height: 22px;

  &.-inputLarge {
    --app-input-height: 44px;
    --app-input-line-height: 38px;
  }

  &.-inputError {
    border-color: var(--app-color-danger);
  }

  @extend .form-control !optional;
  font-size: 1rem;
  padding: 10px 15px;
  height: var(--app-input-height);
  line-height: var(--app-input-line-height);
  border-radius: 5px;
  background-color: #fff;
  border-radius: var(--app-border-radius);

  &::-webkit-search-cancel-button {
    display: none;
  }
  &::placeholder {
    font-size: 1rem;
    color: var(--app-color-grey-dark);
    line-height: var(--app-input-line-height);
  }
}
