@use './mixins.scss' as mixins;

@mixin smallForm {
  .ph-formItem-label {
    width: 100%;
    padding: 5px 0;
    margin-top: 10px;
  }
  .ph-formItem-error {
    color: var(--app-error-border);
    padding: 5px 0;
  }
  .ph-formItem-value {
    width: 100%;

    &.-halfWidth {
      width: calc(50% - 10px);

      &:not(:last-child) {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}
@mixin largeForm {
  .ph-formItem-label {
    min-height: var(--app-formItem-height);
    width: 30%;
  }
  .ph-formItem-error {
    color: var(--app-error-border);
    padding: 5px 0;
  }
  .ph-formItem-value {
    width: 70%;

    &.-halfWidth {
      width: calc(35% - 10px);

      &:not(:last-child) {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
}

:root {
  --app-formItem-height: 40px;

  .ph-formItem-container {
    width: 100%;
    @include mixins.grid;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .ph-formItem-header:not(:first-child) {
      margin-top: 10px;
    }

    &.-smallForm {
      .ph-formItem {
        @include smallForm;
      }
    }
    &.-largeForm {
      .ph-formItem {
        @include largeForm;
      }
    }
    &.-wrapForm {
      flex-direction: row;
      flex-wrap: wrap;
    }
    &:not(.-smallForm):not(.-largeForm) {
      .ph-formItem {
        @include mixins.is-mobile {
          @include smallForm;
        }
        @include mixins.is-desktop {
          @include largeForm;
        }
      }
    }

    > .app-infoPanel {
      margin-left: 15px;
      margin-right: 15px;
    }
  }

  .ph-formItem-header {
    width: 100%;
    height: var(--app-formItem-height);
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    > spinner {
      --spinner-size: 30px;
    }
    > month-selector {
      --monthSelector-height: 26px;
    }
    > week-selector {
      --weekSelector-height: 26px;
    }
  }

  .ph-formItem-row {
    position: relative;
    display: flex;
    flex-direction: row;

    @include mixins.is-mobile {
      flex-wrap: wrap;
    }
  }

  .ph-formItem {
    width: 100%;
    padding: 0 15px;
    margin-bottom: 15px;
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;

    &.-halfWidth {
      width: 50%;
    }
    &.-noPadding {
      padding: 0;
    }
    &.-noLabel {
      height: var(--app-formItem-height);
    }

    .ph-formItem-label {
      color: var(--app-color-grey-dark);
      font-size: 1rem;
      padding-left: 0;
      font-weight: 400;
      display: flex;
      align-items: center;
    }
    .ph-formItem-value {
      min-height: var(--app-formItem-height);
      padding: 0;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;

      &.-sm {
        > input {
          max-width: 130px;
        }
      }
      &.-empty {
        color: var(--app-color-grey-dark) !important;
      }

      > .ph-formItem-valueColour {
        height: calc(var(--app-formItem-height) - 16px);
        width: 4px;
        border-radius: 10px;
        margin-right: 8px;
        flex: 0 0 auto;
      }
      > .ph-formItem-units {
        font-weight: 300;
        margin-left: 10px;
        white-space: nowrap;
      }
      > ph-checkbox {
        margin-right: 10px;
      }
      > input,
      > date-picker {
        width: 100%;
        padding: 0 12px;
        background-color: transparent;
        border: var(--app-border);
        border-radius: 5px;
        transition: all 0.25s;

        &:disabled {
          color: var(--app-color-grey-dark);
        }
        &::placeholder {
          color: var(--app-color-grey);
        }
        &.-invalid {
          border-color: var(--app-error-border);
        }
      }
      > input,
      > time-picker {
        height: var(--app-formItem-height);
      }
      > date-picker {
        --datePicker-height: var(--app-formItem-height);
      }
      > list-drop-down {
        --listDropDown-button-size: var(--app-formItem-height);
        --listDropDown-button-width: 100%;
      }

      span {
        font-weight: 500;
      }
    }
    .ph-formItem-note {
      color: var(--app-color-grey-dark);
      font-size: 1rem;
      font-weight: 400;
      background-color: transparent;
      border: none;
      display: flex;
      align-items: center;
      margin: 0 0 10px 0;
      padding: 0;
    }

    dp-list {
      margin-bottom: 10px;
    }
  }
}

body {
  &.-theme-droppah {
    .ph-formItem {
      .ph-formItem-label {
        color: var(--app-color-white);
        font-weight: 500;
      }
      .ph-formItem-value {
        > input,
        > date-picker {
          background-color: var(--app-color-white);
          padding: 0 15px;
        }
      }
      .ph-formItem-note {
        background-color: var(--app-color-white);
      }
    }
  }
}
