.app-search {
  float: right;
  height: 34px;
  max-width: 250px;
  position: relative;

  i.ion-ios-search {
    position: absolute;
    font-size: 14px;
    left: 12px;
    top: 50%;
    color: #ccc;
    transform: translateY(-50%);
  }
  input {
    @extend .app-input;
    width: 100%;
    padding-left: 30px;
  }
  i.ion-ios-close {
    right: 16px !important;
    font-size: 24px;
  }
}
