.modal-sm {
}
.modal-lg {
  max-width: 600px;
}
.modal-xl {
}

.ph-modal-content {
  padding: 20px;
  border-radius: var(--app-border-radius);

  button {
    padding: 0 15px;
  }

  .ph-modal-header {
    position: relative;
    width: 100%;
    text-align: center;
    color: var(--app-color-dark);
    margin: 0 0 25px 0;
    border-bottom: none;
    padding: 0;

    .ion {
      font-size: 50px;
      color: var(--app-color-grey);
      margin-bottom: 10px;
    }
    .ion-edit {
      font-size: 40px;
    }

    > img {
      margin-bottom: 13px;
      max-width: 100px;

      &.image-icon {
        max-height: 60px;
        max-width: 300px;
        margin: 5px 0 15px 0;
      }
    }
    product-logo {
      --productLogo-scale: 2.5;
      display: block;
      margin: 10px 0 15px 0;
    }

    .ph-finishIcon {
      margin: 7px 0 15px 0;
      width: 30px;
    }

    .headerCenter {
      width: 100%;
      line-height: 28px;
      color: var(--app-color-dark);
      margin-bottom: 10px;
      font-size: 20px;
      font-weight: normal;

      > .headerDetail {
        font-size: 14px;
        color: var(--app-color-grey-dark);
      }
    }

    .headerLeft {
      font-weight: 500;
      font-size: 20px;
      text-align: left;
    }
    .headerRight {
      height: 40px;
      float: right;
    }

    .headerProgress {
      display: flex;
      flex-flow: wrap;
      width: 100%;

      .headerProgress-step {
        flex: 1 1 0px;
        text-align: left;
        color: var(--app-color-grey-dark);
        font-size: 12px;
        transition: all 0.25s;

        &.active {
          color: var(--app-color-flexitime-blue);
          font-weight: 500;
        }

        &.complete {
          color: var(--app-color-success);
          font-weight: 500;
        }
      }

      .headerProgress-track {
        --trackHeight: 3px;

        flex-shrink: 0;
        width: 100%;
        height: var(--trackHeight);
        border-radius: var(--trackHeight);
        margin-top: 5px;
        background-color: var(--app-color-grey);

        div {
          height: var(--trackHeight);
          border-radius: var(--trackHeight);
          background-color: var(--app-color-flexitime-blue);
          transition: all 0.25s;

          &.complete {
            background-color: var(--app-color-success);
          }
        }
      }
    }

    i.ph-modal-close {
      font-size: 30px;
      top: 10px;
      right: 0;

      color: unset;

      &:hover {
        right: -3px;
      }
    }
  }

  .ph-modal-scrollContainer {
    overflow-y: auto;
    max-height: 50vh;
    position: relative;
  }

  .ph-modal-container {
    position: relative;
    padding: 20px 5px;
    border-radius: var(--app-border-radius);
    overflow: auto;
    max-height: 10000px;
    transition: max-height 2s ease;

    p {
      line-height: 1.6;
      padding: 10px;
      text-align: center;
    }

    p:last-child {
      margin-bottom: 0;
    }

    h5 {
      margin: 0;
      text-align: center;
      line-height: 1.5;
      font-size: 12px;
      padding: 10px;
    }

    .dateInputContainer {
      height: 34px;
      float: left;

      & > button {
        float: left;
        width: 34px;
      }
      & > input {
        float: left;
        width: calc(100% - 34px) !important;
      }
    }

    .ph-modal-dateInput {
      width: 115px;
      float: left;
      margin-left: -1px;
      border-radius: 0 2px 2px 0;
      padding: 6px 6px 6px 12px;
      text-align: left;
    }

    .ph-modal-dateInput[type='date']::-webkit-inner-spin-button {
      -webkit-appearance: none;
      display: none;
    }

    .ph-modal-info {
      border-radius: var(--app-border-radius);
      padding: 20px;
      margin-bottom: 15px;
      text-align: center;

      &.warning {
        background-color: var(--app-warning-background);
        border: 1px solid var(--app-warning-border);

        > i {
          color: var(--app-color-yellow);
        }
      }

      &.info {
        background-color: var(--app-info-background);
        border: solid 1px var(--app-info-border);

        > i {
          color: var(--app-color-payhero);
        }
      }

      &.error {
        margin: 10px 10px 0 10px;
        padding: 15px;

        background-color: var(--app-error-background);
        border: solid 1px var(--app-error-border);

        color: var(--app-color-white);
      }

      > i {
        top: 1px;
        margin-right: 4px;
      }

      p {
        display: table;
        padding: 0;
        margin: 20px auto 0 auto;
      }
    }

    .ph-modal-row {
      width: 100%;
      display: block;
      height: auto;

      // Needed to ensure row fits to height of
      // children without the use of overflow hidden
      clear: both;
      &:after {
        clear: both;
        content: '';
        display: table;
      }

      .ph-modal-cellFull,
      .ph-modal-cellHalf,
      .ph-modal-cellThird,
      .ph-modal-cellQuarter,
      .ph-modal-cellFull-splitContent {
        float: left;
        padding: 10px;
        position: relative;
      }

      .ph-modal-cellFull,
      .ph-modal-cellFull-splitContent {
        width: 100%;
      }
      .ph-modal-cellHalf {
        width: 50%;
      }
      .ph-modal-cellThird {
        width: 33%;
      }
      .ph-modal-cellQuarter {
        width: 25%;
      }

      .ph-modal-cellFull,
      .ph-modal-cellHalf,
      .ph-modal-cellThird,
      .ph-modal-cellQuarter {
        .ph-modal-cellHeader {
          font-size: 12px;
          font-weight: 200;
          margin-bottom: 10px;
          width: 100%;
          height: 17px;

          .ph-required-icon {
            font-size: 8px;
            top: -1px;
            left: 3px;
          }
        }
        .ph-modal-cellContent {
          width: 100%;
          min-height: 34px;

          > .radioButtons {
            padding-top: 5px;
          }
        }
      }

      .ph-modal-cellFull-splitContent {
        .ph-modal-cellHeader {
          height: 34px;
          line-height: 34px;
          width: 40%;
          float: left;
          font-weight: 200;

          .ph-required-icon {
            font-size: 8px;
            top: -1px;
            left: 3px;
          }
        }
        .ph-modal-cellContent {
          height: 34px;
          line-height: 34px;
          width: 60%;
          float: right;
        }
      }

      list-selector,
      list-drop-down {
        width: 100%;
      }
      input.form-control {
        width: 100%;
        height: 34px;
        font-size: 12px;
      }
      input.form-control::-ms-clear {
        display: none;
      }
      input.form-control::-webkit-clear-button {
        display: none;
      }

      .timeInputContainer {
        font-size: 12px;
      }

      .timeInputContainer input {
        cursor: pointer;
      }
    }

    .ph-modal-notesRow {
      padding: 15px 10px 10px 10px;

      .ph-modal-notesRowHeader {
        cursor: pointer;
        color: var(--app-color-payhero);
      }
      .ph-modal-notesRowInput {
        width: 100%;
        height: 0;
        padding: 0 10px;
        border: none;
        margin-top: 0;
        max-width: 100%;
        max-height: 300px;
        border-radius: var(--app-border-radius);
        resize: none;
        transition: padding 0.25s ease-out, border 0.25s ease-out, margin-top 0.25s ease-out;

        &.ph-modal-notesRowInputOpen {
          height: 120px;
          padding: 10px;
          border: var(--app-border);
          margin-top: 7px;
        }
      }
    }

    textarea {
      resize: vertical;
      font-size: 12px;
      width: 100%;
      max-width: 100% !important;
      max-height: 200px;
      height: 80px;
      padding: 10px;

      &:focus {
        border-color: #9eccf1 !important;
        border-width: 2px !important;
        outline: 0;
      }
    }

    .ph-modal-row.textarea-row {
      overflow: hidden;
    }

    &.ph-adminModal {
      .ph-modal-info {
        margin-bottom: 30px;
      }

      > h5:not(.ph-modal-info) {
        margin-top: 20px;
      }
    }
  }

  .ph-modal-table {
    margin-bottom: 0;

    .scrollContainer {
      max-height: 500px !important;

      .ph-modal-tableRow {
        background-color: var(--app-color-white);
      }

      list-drop-down {
        margin-top: 8px;
      }
    }
  }

  .ph-modal-btnFooter {
    width: 100%;
    height: 54px;
    position: relative;
    border: none;
    padding: 20px 0 0 0;

    .ph-modal-btnLeft {
      float: left;
      min-width: 100px;
      margin-right: 10px;
    }

    .ph-modal-btnCenter {
      display: block;
      width: 100px;
      margin: 0 auto;
    }

    .ph-modal-btnRight {
      float: right;
      min-width: 100px;
      margin-left: 7px;
    }
  }
}

.iframe-container {
  position: relative;
  height: 470px;
  display: block;
  width: 100%;
  border-radius: 5px;

  > iframe {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 5px;
  }
}

body {
  &.-theme-droppah {
    .modal-dialog {
      background-color: var(--droppah-color-dark-2);
      border-radius: var(--droppah-border-radius);
      overflow: hidden;

      .modal-content {
        background-color: transparent;
      }
    }

    .ph-modal-content {
      color: var(--app-color-white);

      .ph-modal-header > .headerCenter {
        color: var(--app-color-white);
      }
      .ph-modal-btnFooter > button {
        border-radius: var(--droppah-border-radius);

        &.-color-success {
          background-color: var(--app-color-droppah) !important;
        }
      }
    }
    .ph-modal-btnFooter {
      height: 70px;
    }
  }
}
