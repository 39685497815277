@import "./mixins.scss";

.-selected {
  &.ph-teamSelect-teamCard {
    background-color: var(--app-color-grey-very-light) !important;
    box-shadow: inset 0 2px 10px rgba(0, 0, 0, 0.07) !important;
  }
}

.-padded {
  &.dp-back {
    padding: 0 30px;
  }
}

.-mobile-only {
  @include is-mobile {
    display: flex !important;
  }
  @include is-desktop {
    display: none !important;
  }
}
.-desktop-only {
  @include is-mobile {
    display: none !important;
  }
  @include is-desktop {
    display: flex !important;
  }
}
