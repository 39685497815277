body {
  &.-theme-droppah {
    --theme-font-color: var(--app-color-white);
    --theme-link-color: var(--app-color-droppah-lilac);
    --theme-link-hover-color: var(--app-color-droppah);
    --app-border-radius: var(--droppah-border-radius);

    --app-color-success: var(--app-color-droppah);
    --app-color-success-dark: var(--app-color-droppah-dark);
    --app-color-success-translucent: var(--app-color-droppah-translucent);
    --theme-color-accent: var(--app-color-droppah);
  }
  &:not(.-theme-droppah) {
    --theme-font-color: var(--app-color-dark);
    --theme-link-color: var(--app-color-payhero);
    --theme-link-hover-color: var(--app-color-payhero-dark);

    --theme-color-accent: var(--app-color-flexitime-blue);
  }
}
