i {

  //Size
  &.sm-icon {
    font-size: 16px;

    &.ion-ios-checkmark-empty::before {
      font-size: 22px;
    }
  }
  &.md-icon {
    font-size: 22px;

    &.ion-ios-checkmark-empty::before {
      font-size: 30px;
    }
  }
  &.lg-icon {
    font-size: 30px;

    &.ion-ios-checkmark-empty::before {
      font-size: 38px;
    }
  }

  // Close icon styles
  &.ion-ios-close {
    cursor: pointer;
    border-radius: 30px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: content-box;
    transition: all 0.25s ease-out;
    color: var(--app-color-grey-dark);

    &:before {
      top: calc(50%);
      left: calc(50%);
      transform: translate(-50%, -50%);
      position: absolute;
    }

    &.sm-close {
      font-size: 20px;
      padding: 5px;
      height: 10px;
      width: 10px;
      right: 11px;

      &:hover {
        padding: 8px;
        right: 8px;
      }
    }

    &.lg-close {
      font-size: 30px;
      padding: 5px;
      height: 20px;
      width: 20px;
      right: 15px;

      &:hover {
        padding: 8px;
        right: 12px;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }

    &:active {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
}
