@import "./mixins.scss";

.btn {
  border-radius: var(--app-border-radius);

  &:focus {
    box-shadow: none;
  }

  &.btn-left {
    text-align: left;
    padding: 6px 18px;

    &:after {
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
.btn-outline-secondary {
  color: var(--app-color-dark);
  background-color: var(--app-color-white);
  border: var(--app-border-light);

  &:hover {
    background-color: var(--app-color-grey-very-light);
  }
}

body > .dropdown,
body > .dropup {
  z-index: 1065 !important;

  @include is-mobile {
    width: calc(100vw - 20px) !important;
    height: calc(100vw - 20px) !important;
    top: auto !important;
    bottom: 0px !important;
    left: 10px !important;
    transform: none !important;
    top: 10px !important;
    bottom: auto !important;
    display: flex;
  }
}

body {
  &.-theme-droppah {
    .dropdown,
    .dropup {
      &:not(.show) {
      border-radius: var(--app-border-radius);
      border: 3px solid var(--app-color-white);
      padding: 2px 3px;
      background: var(--app-color-white);
      > .dropdown-menu {
        box-shadow: none;
        }
      }
    }
    ::-webkit-scrollbar-thumb {
      border-radius: 10px !important;
    }
  }
}

.dropdown,
.dropup {
  will-change: auto !important;

  > .btn {
    position: relative;
    width: 100%;

    &.dropdown-toggle:focus {
      box-shadow: none;
    }
    &:not(.btn-left) {
      &:after {
        display: none;
      }
    }
  }
  > .dropdown-menu {
    width: 100%;
    margin-top: 10px;
    -webkit-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.2);

    > .dropdown-item {
      height: 34px;
      line-height: 26px;
      font-size: 1rem;

      &:active {
        background-color: var(--app-color-grey-very-light);
      }
      &:focus {
        outline-color: var(--app-color-border);
        color: var(--app-color-dark);
      }
    }
  }
}
.modal-content {
  border: none !important;
  border-radius: 5px !important;
}

.modal-xl {
  max-width: calc(100% - 40px) !important;
}

.dp-datepicker-dropdown {
  padding: 0 !important;
  border: none !important;
  width: 395px !important;

  @include is-mobile {
    max-width: 400px !important;
    width: calc(100vw - 20px) !important;
    margin: 0 auto auto auto !important;
  }

  > ngb-datepicker {
    max-width: 400px !important;
    margin-top: 0 !important;
    font-weight: 400;
  }
}

ngb-datepicker.dropdown-menu {
  box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05) !important;
  border: var(--app-border-light) !important;
  transform: translate(-50%, -50%) !important;
  left: 50% !important;
  top: 50% !important;
  z-index: 1065 !important;

  @include mixins.is-mobile {
    width: calc(100vw - 20px) !important;
  }
}

ngb-datepicker {

  .ngb-dp-day {
    > div {
      &:hover:not(.bg-primary) {
        background-color: var(--app-color-link-translucent) !important;
      }
    }
    .bg-primary {
      background-color: var(--theme-link-color) !important;
      color: var(--app-color-white) !important;
    }
  }
}

ngb-datepicker {
  width: 395px;
  margin-top: 10px !important;
  background-color: var(--app-color-grey-very-light) !important;
  border-radius: 5px !important;
  border: none !important;

  @include is-mobile {
    width: calc(100vw - 20px) !important;
  }

  .ngb-dp-header {
    padding: 15px;

    ngb-datepicker-navigation {
      .ngb-dp-arrow {
        width: 34px;
        height: 34px;

        button.ngb-dp-arrow-btn {
          width: 34px;
          min-width: 0;
          height: 34px;
          padding: 0;
          margin: 0 !important;
          background-color: var(--app-color-white) !important;
          border: var(--app-border) !important;
          border-radius: var(--app-border-radius) !important;
          color: var(--app-color-dark) !important;

          &:focus {
            outline: 0;
          }

          > span.ngb-app-navigation-chevron {
            font-size: 10px;
            margin: 0 !important;
            position: relative;
            top: 0;
            left: 1px;
          }
        }

        &.right {
          span.ngb-app-navigation-chevron {
            top: -1px !important;
            left: -1px !important;
          }
        }
      }
      ngb-datepicker-navigation-select {
        flex: inherit;
        width: 290px;

        select {
          height: 34px;
          margin: 0 5px;
          padding: 0 15px;
          font-size: 1rem;
          font-weight: 500;
          border-radius: 5px;
          border-color: var(--app-color-border);
        }
      }
    }
  }
  .ngb-dp-months {
    position: relative;

    .ngb-dp-month {
      @include ph-card;
      background-color: var(--app-color-white);
      display: block;
      margin: 0 auto;
      width: 100%;
      box-shadow: none;
      border: none;
      border-radius: 0 0 5px 5px;

      ngb-datepicker-month {
        padding: 15px;

        .ngb-dp-week {
          padding: 0 !important;

          &.ngb-dp-weekdays {
            border: none;
            background-color: transparent !important;

            .ngb-dp-weekday {
              width: 52px;
              height: 52px;
              line-height: 52px;
              font-weight: 500;
              font-style: inherit;
              color: var(--app-color-dark);
              font-size: 1rem;
            }
          }

          .ngb-dp-day {
            width: 52px;
            height: 52px;

            > div {
              width: 100%;
              height: 100%;
              line-height: 52px;
              border-radius: var(--app-border-radius);
              font-size: 1rem;
            }
          }
        }
      }
    }
  }
}

ngb-tooltip-window {
  .tooltip-inner {
    padding: 10px 15px;
    font-size: 1rem;
    max-width: 360px;

    @include is-mobile {
      width: 345px;
    }
  }
  &.-smallFont {
    .tooltip-inner {
      font-size: 14px;
    }
  }

  &.-invertColours {
    opacity: 1 !important;
    .tooltip-inner {
      background-color: var(--app-color-white);
      color: var(--app-color-black);
    }
    .tooltip-arrow {
      opacity: 0;
    }
  }

}

.cost-popover {
  opacity: 1 !important;

  .arrow::before {
    border-bottom-color: var(--app-color-white);
  }

  .tooltip-inner {
    max-width: 100%;
    box-shadow: var(--app-box-shadow-dark);
    background-color: var(--app-color-white);
  }

  @include is-mobile {
    transform: none !important;
    left: 10px !important;
    top: 5px !important;

    .arrow::before {
      display: none;
    }

    .tooltip-inner {
      width: calc(100vw - 20px);
    }
  }
}
