@use "./mixins.scss" as mixins;

.app-auth-welcome {
  margin: 40px 0 30px;
  padding: 15px;
  color: var(--theme-font-color);

  & > * {
    text-align: center;
    font-weight: 200;
  }
  h1 {
    font-weight: 500;
    padding: 0;
  }
  h4 {
    font-size: 1rem;
    font-weight: 400;
  }

  .app-auth-welcomeLogo {
    width: 85%;
    max-width: 200px;
    margin: 0 auto 40px;
    display: block;
  }

  @include mixins.is-mobile {
    margin-top: 0;
    margin-bottom: 30px;

    .app-auth-welcomeLogo {
      margin-left: 0;
      max-width: 130px;
    }
  }
}

:root {
  .app-auth-logo {
    max-height: 55px;
    width: 80%;
    display: block;
    margin: 70px auto;

    @include mixins.is-mobile {
      margin: 40px auto;
      padding: 0 40px;
    }

    //new design changes
    &.-externalPage {
      margin-bottom: 10px;
      margin-top: 50px;
      max-height: 60px;

      @include mixins.is-mobile {
        margin-bottom: 5px;
      }
    }
  }

  .app-auth-domainLogo {
    max-height: 180px;
    display: block;
    margin: 60px auto 40px;

    @include mixins.is-mobile {
      margin: 40px auto;
      padding: 0 40px;
    }
  }

  .app-auth-header {
    font-size: 30px;
    font-weight: 200;
    display: block;
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }

  //new app-auth-contentContainer associated with -signup-or-login-page class for app-auth-card
  .app-auth-contentContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .-productGraphic {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: -100px;

      > img {
        height: 100%;
        min-height: 325px;
        max-height: 100%;
        max-width: 550px;
      }
    }
  }

  .app-auth-card {
    @include mixins.app-card;
    width: calc(100% - 40px);
    max-width: 400px;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &.-whiteCard {
      background-color: var(--app-color-white);
      border: var(--app-border-light);
      border-radius: var(--app-border-radius);
      box-shadow: var(--app-box-shadow);
      max-width: 450px;
    }

    .app-auth-errorMessage {
      width: 100%;
      padding: 10px;
      text-align: center;
      color: var(--app-color-white);
      line-height: 1.5;
      font-size: 12px !important;
      opacity: 1;
      background-color: var(--app-error-background);
      border: 1px solid var(--app-error-border);
      border-radius: var(--app-border-radius);
      margin-bottom: 10px;
      transition:
        height var(--app-transition),
        margin var(--app-transition),
        padding var(--app-transition),
        opacity var(--app-transition);

      i {
        position: relative;
        top: 1px;
        margin-right: 5px;
      }

      &.-hidden {
        height: 0;
        margin: 0 0 0 0;
        padding: 0 10px;
        opacity: 0;
      }
    }

    .app-auth-inputLabel {
      color: var(--app-color-grey-dark);
      width: 100%;
      height: 2rem;
      padding-left: 0.5rem;
    }

    & > h2 {
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      margin: 0 0 25px 0;
      color: var(--app-color-grey-dark);
    }

    .ph-formItem-container {
      margin-bottom: 0;

      .ph-formItem {
        .ph-formItem-label {
          margin-top: 0;
        }
      }
    }

    .app-auth-form {
      position: relative;

      > * + * {
        margin-top: 10px;
      }

      .fieldLabel {
        color: var(--app-color-grey-dark);
        font-size: 0.9rem;
        display: flex;
      }

      input:read-only {
        background-color: var(--app-color-grey-light);
      }

      .password-field {
        position: relative;

        .app-auth-forgot {
          position: absolute;
          top: 50%;
          right: 15px;
          transform: translateY(-50%);
          color: var(--app-color-grey-dark);
          cursor: pointer;
          font-size: 0.9rem;
        }
      }
    }

    .app-login-message {
      font-size: 0.85rem;
      color: var(--theme-font-color);
      text-align: center;
      margin-bottom: 20px;
    }

    .app-auth-submit {
      --button-height: 50px;
      width: 100%;
    }
  }

  ////////new design stuff
  .app-header-byFlexitime {
    display: flex;
    justify-content: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }

  .app-auth-pageTitleContainer {
    margin: 40px 0 20px;
    display: flex;
    flex-direction: column;

    .app-auth-pageTitle {
      font-size: 30px;
      font-weight: 600;
    }
  }
  .app-auth-card {
    &.-signup-or-login-page {
      --card-font-size: 14px;

      box-shadow: 0 0 100px rgba(0, 0, 0, 0.1);
      border: none;
      border-radius: var(--app-border-radius-large);
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 0;
      min-width: 370px;

      @include is-mobile {
        min-width: 270px;
      }

      .app-infoPanel {
        --top: var(--app-border-radius-large);
        border-radius: var(--top) var(--top) 0 0;
        background-color: var(--app-color-flexitime-blue);
        color: var(--app-color-white);
        padding: 25px;
        border: none;

        a {
          color: var(--app-color-white);
          text-decoration: underline;
        }
        span {
          text-decoration: underline;
          position: relative;
        }
      }

      .app-auth-card-inner {
        padding: 25px;
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;

        &.-submitting {
          @include mixins.loadingOpacity;
          pointer-events: none;
        }
        &.-hidden {
          visibility: hidden;
          pointer-events: none;
        }
      }

      integration-button {
        text-align: center;

        .integrationButton-outer {
          height: auto;
          position: relative;
          padding: 18px 25px 18px 30px;

          .integrationButton-inner {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              position: absolute;
              display: block;
              left: 15px;
              height: 25px !important;
            }
            span {
              font-weight: 600 !important;
            }
          }
        }
      }

      .app-integrationsDivider {
        padding: 20px 10px;
        margin-bottom: 10px;

        .app-integrationsDivider-text {
          font-size: var(--card-font-size);
          max-width: 70%;
          width: max-content;
          text-align: center;
        }
      }

      .app-auth-form {
        width: 100%;
      }

      .fieldLabel {
        font-size: 14px;
        position: relative;

        &:not(:first-child) {
          margin-top: 25px;
        }

        .app-auth-forgot {
          position: absolute;
          right: 0;
          cursor: pointer;
          font-size: 12px;
          font-weight: 500;
          color: var(--app-theme-color);
        }
      }
      input {
        margin-top: 3px;
        font-size: var(--card-font-size);
      }
      .password-field {
        margin-top: 0;

        .-showPasswordIcon {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          padding: 0 5px;

          i {
            color: var(--app-color-grey) !important;
          }
        }
      }

      .app-auth-submit {
        margin-top: 25px;
        font-size: var(--card-font-size);
        font-weight: 600;
        color: var(--app-color-white);
        background-color: var(--app-theme-color);
      }
    }
  }

  .app-auth-accessAgreement {
    display: flex;
    align-items: center;
    background-color: var(--app-color-grey-light);
    padding: 20px;
    border-radius: var(--app-border-radius);
    font-size: 12px;
    font-weight: 500;

    ph-checkbox {
      flex: 0 0 auto;
      margin-right: 15px;
    }

    a {
      text-decoration: underline;
    }
  }

  .app-auth-textLink {
    color: var(--app-theme-color);
    text-align: center;
    //margin-top: 20px;
    cursor: pointer;
    font-size: inherit;
    margin-top: 25px;
    font-weight: 500;

    a {
      color: var(--app-theme-color);

      &:hover {
        color: var(--app-theme-color);
      }
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .app-auth-mobileGraphicFooter {
    display: none;
  }

  @include is-mobile {
    .app-auth-pageTitleContainer {
      margin-bottom: 0;
    }

    .-app-auth-contentContainer {
      margin-bottom: 0;
      margin-top: 10px;
    }

    .-productGraphic {
      display: none !important;
    }

    .app-auth-card {
      box-shadow: none !important;
      width: 100%;
      border: none !important;

      padding: 0 !important;
    }

    .app-auth-card-inner {
      padding: 0 1px !important;
    }
    .app-infoPanel {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    .app-auth-mobileGraphicFooter {
      display: flex !important;
      position: relative;
      align-items: flex-end;
      justify-content: center;
      margin-top: 20px;
      flex-grow: 1;

      > img {
        display: flex;
        flex-grow: 1;
        min-height: 160px;
      }
    }
  }
}

body {
  &.-theme-droppah {
    .app-auth-welcome {
      .app-auth-welcomeLogo {
        @include mixins.is-desktop {
          max-width: 300px;
        }
      }
    }
    .app-auth-accessAgreement {
      width: 100%;
      background-color: var(--droppah-color-dark-2);
      border-radius: var(--droppah-border-radius);
      color: var(--app-color-white);
    }
  }
}
