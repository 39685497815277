// Boostrap colour variable overrides
$theme-colors: (
  'primary': #00adef,
  'secondary': #efefef,
  'success': #5eb22e,
  'danger': #d9534f,
  'warning': #fdd835,
);

:root {
  // Colour variables
  --app-color-payhero: #00adef;
  --app-color-payhero-dark: #0094d6;
  --app-color-payhero-very-dark: #0086af;
  --app-color-payhero-translucent: #00adef20;

  --app-color-invoxy: #5BA079;
  --app-color-invoxy-dark: #3F7157;
  --app-color-invoxy-translucent: #5BA07920;

  --app-color-karmly: #ef7812;
  --app-color-karmly-dark: #bd5b05;
  --app-color-karmly-translucent: #ef781233;

  --app-color-droppah: #763eff;
  --app-color-droppah-dark: #520bff;
  --app-color-droppah-translucent: #763eff20;

  --app-color-droppah-lilac: #AFAFFF;

  //old main droppah colour
  --app-color-droppah-green: #429d22;
  --app-color-droppah-green-dark: #397d12;
  --app-color-droppah-green-translucent: #45991520;

  --app-color-flexitime-blue: #004763;
  --app-color-flexitime-blue-light: #00668f;
  --app-color-flexitime-blue-dark: #002533;
  --app-color-flexitime-yellow: #ffad3d;
  --app-color-flexitime-yellow-dark: #d89232;

  --app-color-primary: #337ab7;
  --app-color-primary-dark: #1a619e;
  --app-color-primary-translucent: #337ab720;
  --app-color-success: #5eb22e;
  --app-color-success-dark: #459915;
  --app-color-success-translucent: #5eb22e20;
  --app-color-danger: #d9534f;
  --app-color-danger-dark: #c03a36;
  --app-color-danger-translucent: #d9534f20;
  --app-color-yellow: #fdd835;
  --app-color-yellow-dark: #e4bf1c;
  --app-color-yellow-translucent: #fdd83520;

  --app-color-grey: #ccc;
  --app-color-grey-dark: #999;
  --app-color-grey-very-dark: #777;
  --app-color-grey-light: #efefef;
  --app-color-grey-very-light: #fafafa;

  --app-color-dark: #333;
  --app-color-black: #000000;
  --app-color-white: #ffffff;

  --app-color-black-translucent: #00000060;

  // --app-color-link: #118be0;
  // --app-color-link-dark: #1383d3;
  --app-color-link: #00adef;
  --app-color-link-dark: #0094d6;
  --app-color-link-translucent: #118be020;

  --app-color-border: #dedede;
  --app-color-border-light: #f2f2f2;
  --app-color-border-dark: var(--app-color-grey);
  --app-color-border-focus: #9eccf1;

  --app-color-footer-grey: #ebebeb;

  --app-error-background: #b22e2e;
  --app-error-border: #b22e2e;

  // --app-error-background: #b22e2ebf;
  // --app-error-border: #b22e2ebf;

  --app-info-background: #00adef1a;
  --app-info-border: #00adef80;

  --app-warning-background: #f1ca031a;
  --app-warning-border: #f1ca0380;

  // Border variables
  --app-border: 1px solid var(--app-color-border);
  --app-border-light: 1px solid var(--app-color-border-light);
  --app-border-dark: 1px solid var(--app-color-border-dark);
  --app-border-dashed: 1px dashed var(--app-color-border);
  --app-border-dashed-large: 2px dashed var(--app-color-border);
  --app-border-radius: 5px;
  --app-border-radius-large: 10px;

  // Misc variables
  --app-focus: rgba(0, 0, 0, 0.075) 0px 1px 1px inset, var(--app-color-border-focus) 0px 0px 0px 2px;
  --app-focus-droppah: var(--app-color-droppah) 0px 1px 1px inset, var(--app-color-droppah) 0px 0px 0px 2px;

  --app-transition: 0.2s ease-in;
  --app-transition-longer: 0.3s ease-in;
  --app-box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  --app-box-shadow-dark: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
  --app-box-shadow-inset: inset 0 0 1rem 0 rgba(0, 0, 0, 0.05);
  --app-box-shadow-inset-dark: inset 0 0 1rem 0 rgba(0, 0, 0, 0.2);

  --app-nav-width: 240px;
  --app-content-width: 1300px;
  --app-content-padding: 1.5rem 2rem;

  --droppah-border-radius: 25px;

  --droppah-color-dark-1: #1e1e1e;
  --droppah-color-dark-2: #343434;
  --droppah-color-dark-3: #4a4a4a;
  --droppah-color-dark-4: #616161;

  --droppah-color-stone: #EBEAE4;

  --droppah-color-orange: #ffa571;
  --droppah-color-bright-orange: #ff6622;

  --droppah-color-bright-orange-dark: #622d15;

  --droppah-color-honey: #ffb446;

  --droppah-color-lilac: #afafff;

  --droppah-color-green: #84ca80;
  --droppah-color-green-dark: #2b3d2a;

  --droppah-color-emerald: #136841;
  --droppah-color-mint: #84ca80;
  --droppah-color-lime: #bdde9a;
  --droppah-color-ruby: #a40d4c;
  --droppah-color-bubblegum: #ffa7cb;
  --droppah-color-sky: #9acbff;
  --droppah-color-dusk: #483680;


  --droppah-color-stone: #ebeae4;

  --droppah-color-red: #c21313;
  // Variables for handling css values with a fallback for browsers with limited support
  --app-overflow-overlay: overlay;
  @supports not (overflow: overlay) {
    --app-overflow-overlay: auto;
  }
}

$font-path: '/assets/fonts';

@font-face {
  font-family: 'Work Sans';
  src: url($font-path + '/Work_Sans/WorkSans-Light.ttf');
  font-weight: 200;
}

@font-face {
  font-family: 'Work Sans';
  src: url($font-path + '/Work_Sans/WorkSans-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Work Sans';
  src: url($font-path + '/Work_Sans/WorkSans-SemiBold.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Work Sans';
  src: url($font-path + '/Work_Sans/WorkSans-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: 'Work Sans';
  src: url($font-path + '/Work_Sans/WorkSans-ExtraBold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Sharp Grotesk';
  src: url($font-path + '/Sharp_Grotesk/Sharp_Grotesk_Book.woff2');
  font-weight: 200;
}

@font-face {
  font-family: 'Sharp Grotesk';
  src: url($font-path + '/Sharp_Grotesk/Sharp_Grotesk_Book.woff2');
  font-weight: 300;
}

@font-face {
  font-family: 'Sharp Grotesk';
  src: url($font-path + '/Sharp_Grotesk/Sharp_Grotesk_Book.woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'Sharp Grotesk';
  src: url($font-path + '/Sharp_Grotesk/Sharp_Grotesk_Medium.woff2');
  font-weight: 500;
}

@font-face {
  font-family: 'Sharp Grotesk';
  src: url($font-path + '/Sharp_Grotesk/Sharp_Grotesk_Medium.woff2');
  font-weight: 600;
}

@font-face {
  font-family: 'Sharp Grotesk';
  src: url($font-path + '/Sharp_Grotesk/Sharp_Grotesk_Medium.woff2');
  font-weight: 800;
}
