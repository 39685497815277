@import './mixins.scss';

button {
  --button-height: 34px;
  --button-iconSize: 23px;
  --button-fontSize: 1rem;

  @include user-select-disabled;
  font-size: var(--button-fontSize);
  height: var(--button-height);
  min-width: 100px;
  max-width: 100%;
  color: var(--app-color-dark);
  border-radius: var(--app-border-radius);
  border: 1px solid transparent;
  text-transform: none;
  font-weight: 500;
  box-shadow: none !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;
  cursor: pointer;
  touch-action: manipulation;
  transition: background-color var(--app-transition);
  position: relative;

  > i,
  > i.ion {
    font-size: min(var(--button-iconSize), calc(var(--button-height) - 2px));
    flex: 0 1 auto;

    &.ion-md-open {
      font-size: 12px;
    }
  }
  > img {
    height: min(var(--button-iconSize), calc(var(--button-height) - 2px));
    flex: 0 1 auto;
  }
  > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &.-centerAlign {
    text-align: center;
  }
  &:disabled {
    pointer-events: none;
  }
  &.btn {
    border: none;
  }
  &[ngbDropdownToggle] {
    &::after {
      display: none !important;
    }
  }

  > :not(:first-child):not(tooltip-popover) {
    margin-left: 10px;
  }

  // Button Type Modifiers ///////////////////////////////
  &.-full {
    width: 100%;
  }
  &.-type-icon {
    width: var(--button-height);
    min-width: var(--button-height);
    padding: 0;
  }
  &.-type-text {
    justify-content: flex-start;
    padding: 0;
    text-align: left;
    min-width: 0;
  }
  &.-type-dropdown {
    --button-iconSize: 1.3rem;
    min-width: 120px;
    justify-content: space-between !important;
    padding: 0 10px;

    > span {
      text-align: left;
    }
    > i {
      color: var(--app-color-grey-dark);
      min-width: 12px;
    }
  }
  &.-type-colourpicker {
    width: var(--button-height);
    min-width: var(--button-height);
    padding: 5px;

    > .buttonColor {
      width: 100%;
      height: calc(var(--button-height) - 10px - 2px);
      border-radius: var(--app-border-radius);
    }
  }

  // Button Size Modifiers ///////////////////////////////
  &.-size-btn-lg {
    --button-height: 40px !important;
  }
  &.-size-btn-sm {
    --button-height: 24px !important;
  }
  &.-size-icon-lg {
    --button-iconSize: 34px !important;
  }
  &.-size-icon-sm {
    --button-iconSize: 16px !important;
  }
  &.-size-text-lg {
    --button-fontSize: 22px !important;
  }
  &.-size-text-sm {
    --button-fontSize: 0.9rem !important;
  }

  // Button Colour Modifiers /////////////////////////////
  @mixin buttonDisabledTextColor {
    color: var(--app-color-grey) !important;
    > i {
      color: var(--app-color-grey) !important;
    }
  }

  &.-color-link-clear {
    background-color: transparent !important;
    color: var(--app-color-link) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-link) !important;
    }
    > i {
      color: var(--app-color-link) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-link-outline {
    color: var(--app-color-link-dark) !important;
    background-color: var(--app-color-link-translucent) !important;
    border-color: var(--app-color-link) !important;
    &:active {
      color: var(--app-color-link-dark) !important;
      background-color: var(--app-color-link-translucent) !important;
      border-color: var(--app-color-link) !important;
    }
    &:hover {
      color: var(--app-color-link-dark) !important;
      background-color: var(--app-color-link-translucent) !important;
      border-color: var(--app-color-link) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-link-outline-dashed {
    @extend .-color-link-outline;
    border-style: dashed;
  }

  &.-color-success {
    color: var(--app-color-white) !important;
    background-color: var(--app-color-success) !important;
    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-success) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-success-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-success-clear {
    background-color: transparent !important;
    color: var(--app-color-success) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-success) !important;
    }
    > i {
      color: var(--app-color-success) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-success-outline {
    color: var(--app-color-success-dark) !important;
    background-color: var(--app-color-success-translucent) !important;
    border-color: var(--app-color-success) !important;
    > i {
      color: var(--app-color-success) !important;
    }
    &:active {
      color: var(--app-color-success-dark) !important;
      background-color: var(--app-color-success-translucent) !important;
      border-color: var(--app-color-success) !important;
    }
    &:hover {
      color: var(--app-color-success-dark) !important;
      background-color: var(--app-color-success-translucent) !important;
      border-color: var(--app-color-success) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-success-outline-dashed {
    @extend .-color-success-outline;
    border-style: dashed;
  }

  &.-color-primary {
    color: var(--app-color-white) !important;
    background-color: var(--app-color-primary) !important;
    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-primary) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-primary-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-primary-clear {
    background-color: transparent !important;
    color: var(--app-color-primary) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-primary) !important;
    }
    > i {
      color: var(--app-color-primary) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-primary-outline {
    color: var(--app-color-primary-dark) !important;
    background-color: var(--app-color-primary-translucent) !important;
    border-color: var(--app-color-primary) !important;
    &:active {
      color: var(--app-color-primary-dark) !important;
      background-color: var(--app-color-primary-translucent) !important;
      border-color: var(--app-color-primary) !important;
    }
    &:hover {
      color: var(--app-color-primary-dark) !important;
      background-color: var(--app-color-primary-translucent) !important;
      border-color: var(--app-color-primary) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-primary-outline-dashed {
    @extend .-color-primary-outline;
    border-style: dashed;
  }

  &.-color-payhero {
    color: var(--app-color-white) !important;
    background-color: var(--app-color-payhero) !important;
    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-payhero) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-payhero-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-payhero-clear {
    background-color: transparent !important;
    color: var(--app-color-payhero) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-payhero) !important;
    }
    > i {
      color: var(--app-color-payhero) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-payhero-outline {
    color: var(--app-color-payhero-dark) !important;
    background-color: var(--app-color-payhero-translucent) !important;
    border-color: var(--app-color-payhero) !important;
    &:active {
      color: var(--app-color-payhero-dark) !important;
      background-color: var(--app-color-payhero-translucent) !important;
      border-color: var(--app-color-payhero) !important;
    }
    &:hover {
      color: var(--app-color-payhero-dark) !important;
      background-color: var(--app-color-payhero-translucent) !important;
      border-color: var(--app-color-payhero) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-payhero-outline-dashed {
    @extend .-color-payhero-outline;
    border-style: dashed;
  }

  &.-color-droppah {
    color: var(--app-color-white) !important;
    background-color: var(--app-color-droppah) !important;
    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-droppah) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-droppah-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-droppah-clear {
    background-color: transparent !important;
    color: var(--app-color-droppah) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-droppah) !important;
    }
    > i {
      color: var(--app-color-droppah) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-droppah-outline {
    color: var(--app-color-droppah-dark) !important;
    background-color: var(--app-color-droppah-translucent) !important;
    border-color: var(--app-color-droppah) !important;
    &:active {
      color: var(--app-color-droppah-dark) !important;
      background-color: var(--app-color-droppah-translucent) !important;
      border-color: var(--app-color-droppah) !important;
    }
    &:hover {
      color: var(--app-color-droppah-dark) !important;
      background-color: var(--app-color-droppah-translucent) !important;
      border-color: var(--app-color-droppah) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-droppah-outline-dashed {
    @extend .-color-droppah-outline;
    border-style: dashed;
  }

  &.-color-invoxy {
    color: var(--app-color-white) !important;
    background-color: var(--app-color-invoxy) !important;
    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-invoxy) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-invoxy-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-invoxy-clear {
    background-color: transparent !important;
    color: var(--app-color-invoxy) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-invoxy) !important;
    }
    > i {
      color: var(--app-color-invoxy) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-invoxy-outline {
    color: var(--app-color-invoxy-dark) !important;
    background-color: var(--app-color-invoxy-translucent) !important;
    border-color: var(--app-color-invoxy) !important;
    &:active {
      color: var(--app-color-invoxy-dark) !important;
      background-color: var(--app-color-invoxy-translucent) !important;
      border-color: var(--app-color-invoxy) !important;
    }
    &:hover {
      color: var(--app-color-invoxy-dark) !important;
      background-color: var(--app-color-invoxy-translucent) !important;
      border-color: var(--app-color-invoxy) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-invoxy-outline-dashed {
    @extend .-color-invoxy-outline;
    border-style: dashed;
  }

  &.-color-karmly {
    color: var(--app-color-white) !important;
    background-color: var(--app-color-karmly) !important;
    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-karmly) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-karmly-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-karmly-clear {
    background-color: transparent !important;
    color: var(--app-color-karmly) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-karmly) !important;
    }
    > i {
      color: var(--app-color-karmly) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-karmly-outline {
    color: var(--app-color-karmly-dark) !important;
    background-color: var(--app-color-karmly-translucent) !important;
    border-color: var(--app-color-karmly) !important;
    &:active {
      color: var(--app-color-karmly-dark) !important;
      background-color: var(--app-color-karmly-translucent) !important;
      border-color: var(--app-color-karmly) !important;
    }
    &:hover {
      color: var(--app-color-karmly-dark) !important;
      background-color: var(--app-color-karmly-translucent) !important;
      border-color: var(--app-color-karmly) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-karmly-outline-dashed {
    @extend .-color-karmly-outline;
    border-style: dashed;
  }

  &.-color-flexitime {
    color: var(--app-color-white) !important;
    background-color: var(--app-color-flexitime-blue) !important;
    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-flexitime-blue) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-flexitime-blue-light) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-flexitime-clear {
    background-color: transparent !important;
    color: var(--app-color-flexitime-blue) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-flexitime-blue) !important;
    }
    > i {
      color: var(--app-color-flexitime-blue) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-flexitime-yellow {
    color: var(--app-color-dark) !important;
    background-color: var(--app-color-flexitime-yellow) !important;
    &:active {
      color: var(--app-color-dark) !important;
      background-color: var(--app-color-flexitime-yellow) !important;
    }
    &:hover {
      color: var(--app-color-dark) !important;
      background-color: var(--app-color-flexitime-yellow-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }

  &.-color-danger {
    color: var(--app-color-white) !important;
    background-color: var(--app-color-danger) !important;
    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-danger) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-danger-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-danger-clear {
    background-color: transparent !important;
    color: var(--app-color-danger) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-danger) !important;
    }
    > i {
      color: var(--app-color-danger) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-danger-outline {
    color: var(--app-color-danger-dark) !important;
    background-color: var(--app-color-danger-translucent) !important;
    border-color: var(--app-color-danger) !important;
    &:active {
      color: var(--app-color-danger-dark) !important;
      background-color: var(--app-color-danger-translucent) !important;
      border-color: var(--app-color-danger) !important;
    }
    &:hover {
      color: var(--app-color-danger-dark) !important;
      background-color: var(--app-color-danger-translucent) !important;
      border-color: var(--app-color-danger) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-danger-outline-dashed {
    @extend .-color-danger-outline;
    border-style: dashed;
  }
  &.-color-danger-hoverFill {
    @extend .-color-danger-clear;

    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-danger) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-danger) !important;
    }
  }
  &.-color-danger-dark {
    color: var(--app-color-white) !important;
    background-color: var(--app-color-black-translucent) !important;
    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-black-translucent) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-danger-dark) !important; //TODO
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important; //TODO
    }
  }

  &.-color-yellow {
    color: var(--app-color-white) !important;
    background-color: var(--app-color-yellow) !important;
    &:active {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-yellow) !important;
    }
    &:hover {
      color: var(--app-color-white) !important;
      background-color: var(--app-color-yellow-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-yellow-clear {
    background-color: transparent !important;
    color: var(--app-color-yellow) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-yellow) !important;
    }
    > i {
      color: var(--app-color-yellow) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-yellow-outline {
    color: var(--app-color-yellow-dark) !important;
    background-color: var(--app-color-yellow-translucent) !important;
    border-color: var(--app-color-yellow) !important;
    &:active {
      color: var(--app-color-yellow-dark) !important;
      background-color: var(--app-color-yellow-translucent) !important;
      border-color: var(--app-color-yellow) !important;
    }
    &:hover {
      color: var(--app-color-yellow-dark) !important;
      background-color: var(--app-color-yellow-translucent) !important;
      border-color: var(--app-color-yellow) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-yellow-outline-dashed {
    @extend .-color-yellow-outline;
    border-style: dashed;
  }

  &.-color-grey {
    background-color: var(--app-color-grey-light) !important;
    color: var(--app-color-grey-dark) !important;
    &:active {
      background-color: var(--app-color-grey-light) !important;
      color: var(--app-color-grey-dark) !important;
    }
    > i {
      color: var(--app-color-grey-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-grey-clear {
    background-color: transparent !important;
    color: var(--app-color-grey-dark) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-grey-dark) !important;
    }
    &:hover {
      > i:not(.-disable-hover) {
        color: var(--app-color-grey) !important;
      }
    }
    > i {
      color: var(--app-color-grey-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }

  &.-color-white {
    background-color: var(--app-color-white) !important;
    color: var(--app-color-dark) !important;
    &:active {
      background-color: var(--app-color-white) !important;
      color: var(--app-color-dark) !important;
    }
    > i {
      color: var(--app-color-dark) !important;
    }
    &:disabled {
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-white-clear {
    background-color: transparent !important;
    color: var(--app-color-white) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-white) !important;
    }
    > i {
      color: var(--app-color-white) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-white-outline {
    background-color: var(--app-color-white) !important;
    border: var(--app-border) !important;
    color: var(--app-color-dark) !important;
    > i {
      color: var(--app-color-dark) !important;
    }
    &:active {
      background-color: var(--app-color-white) !important;
      color: var(--app-color-dark) !important;
    }
    &:hover {
      background-color: var(--app-color-grey-very-light) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-white-outline-dashed {
    @extend .-color-white-outline;
    border-style: dashed;
  }

  &.-color-default {
    background-color: var(--app-color-grey-light) !important;
    color: var(--app-color-dark) !important;
    &:active {
      background-color: var(--app-color-grey-light) !important;
      color: var(--app-color-dark) !important;
    }
    > i {
      color: var(--app-color-dark) !important;
    }
    &:hover {
      background-color: var(--app-color-grey) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
  &.-color-default-clear {
    background-color: transparent !important;
    color: var(--app-color-dark) !important;
    &:active {
      background-color: transparent !important;
      color: var(--app-color-dark) !important;
    }
    > i {
      color: var(--app-color-dark) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
    }
  }
  &.-color-default-outline {
    background-color: var(--app-color-grey-light) !important;
    border: var(--app-border) !important;
    color: var(--app-color-dark) !important;

    &:active {
      background-color: var(--app-color-grey-light) !important;
      color: var(--app-color-dark) !important;
    }
    > i {
      color: var(--app-color-dark) !important;
    }
  }
  &.-color-default-outline-dashed {
    @extend .-color-default-outline;
    border-style: dashed;
  }
  &.-color-default-link {
    background-color: var(--app-color-grey-light) !important;
    color: var(--app-color-link) !important;
    &:active {
      background-color: var(--app-color-grey-light) !important;
      color: var(--app-color-link) !important;
    }
    > i {
      color: var(--app-color-link) !important;
    }
    &:hover {
      background-color: var(--app-color-grey) !important;
    }
    &:disabled {
      @include buttonDisabledTextColor;
      background-color: var(--app-color-grey-light) !important;
    }
  }
}

body {
  &.-theme-droppah {
    button {
      --button-height: 50px;

      &.-type-dropdown {
        padding: 0 15px;
      }
    }
  }
}
