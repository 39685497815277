@import "./mixins.scss";

:root {
  .ph-auth-logo {
    height: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    @media screen and (max-width: 549px) {
      & {
        max-width: calc(100% - 60px);
      }
    }
  }

  .ph-auth-card {
    @include ph-card;
    width: calc(100% - 40px);
    padding: 40px;
    max-width: 450px;
    display: block;
    margin: 200px auto 30px auto;

    @media screen and (max-width: 549px) {
      & {
        padding: 30px 20px 20px 20px;
      }
    }

    &.-flexitime {
      background-color: #009BD6;
      padding: 7px;
      border-radius: 0;
      box-shadow: -2px 5px 5px rgba(0, 0, 0, 0.3);
      border: none;
      max-width: 400px;

      .ph-flexitime-loginTitle {
        color: #fff;
        font-size: 14px;
        padding-left: 5px;
        margin-bottom: 7px;
      }

      .ph-flexitime-loginInner {
        background-color: #F4F9FF;
        padding: 10px 25px 25px;
        border-radius: 0;

        .ph-auth-logo {
          display: block;
          width: 100%;
          max-width: 300px;
          height: auto;
          position: relative;
          top: 0;
          left: 0;
          transform: none;
          margin: 10px auto 20px;
        }

        .ph-flexitime-loginInputHolder {
          display: flex;
          align-items: center;
          margin-bottom: 10px;

          > p {
            width: 25%;
            font-size: 12px;
            margin-bottom: 0;
          }

          > input {
            width: 75%;
            height: 35px;
            margin-bottom: 0;
            border-radius: 0;
            padding: 5px 10px;
          }

          > * + * {
            margin-left: 20px;
          }
        }

        .btn-success {
          display: block;
          margin-left: auto;
          height: 35px;
          width: 100%;
          max-width: 120px;
          font-weight: 600;
        }

        .ph-auth-forgot {
          position: relative;
          cursor: pointer;
          top: 0;
          right: 0;
          margin-top: 10px;
          text-align: right;
          color: #00adef;
        }

        .ph-flexitime-loginDisclaimer {
          text-align: center;
          margin-bottom: 0;
          margin-top: 40px;
          line-height: 1.5;
        }
      }
    }

    .ph-auth-errorMessage {
      width: 100%;
      padding: 10px;
      text-align: center;
      color: var(--app-color-white);
      line-height: 1.5;
      opacity: 1;
      background-color: var(--app-error-background);
      border: 1px solid var(--app-error-border);
      border-radius: var(--app-border-radius);
      margin-bottom: 20px;
      transition: height var(--app-transition),
                  margin var(--app-transition),
                  padding var(--app-transition),
                  opacity var(--app-transition);

      i {
        position: relative;
        top: 1px;
        margin-right: 5px;

      }

      &.-hidden {
        height: 0;
        margin: 0;
        padding: 0 10px;
        opacity: 0;
      }
    }

    & > h2 {
      text-align: center;
      font-weight: 400;
      font-size: 20px;
      margin: 0 0 25px 0;
    }

    .ph-auth-form {
      position: relative;

      input:read-only {
        background-color: var(--app-color-grey-light);
      }
      input {
        border: var(--app-border);
        border-radius: var(--app-border-radius);
        margin-bottom: 10px;
      }

      .ph-auth-forgot {
        position: absolute;
        top: 75px;
        right: 15px;
        color: var(--app-color-grey-dark);
        cursor: pointer;
        font-size: 12px;
      }
    }

    .ph-auth-submit {
      --button-height: 50px;
      width: 100%;
      margin-top: 15px;
    }
  }

  .ph-auth-textLink {
    color: var(--app-color-white);
    text-align: center;
    margin: 15px 0;
    cursor: pointer;
    font-size: 13px;

    a {
      color: var(--app-color-white);
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .ph-auth-footer {
    background-color: #0077a2;
    position: absolute;
    bottom: 0;
  }
}
