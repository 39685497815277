@mixin is-mobile {
  @media (max-width: 850px) {
    @content;
  }
}
@mixin is-desktop {
  @media (min-width: 851px) {
    @content;
  }
}

@mixin mobile-fullWidth {
  @include is-mobile {
    max-width: 100vw;
    margin: 0;
  }
}

@mixin text-ellipsis {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin ph-card {
  background-color: var(--app-color-white);
  border: var(--app-border-light);
  border-radius: var(--app-border-radius);
  box-shadow: var(--app-box-shadow);
  overflow: hidden;
  position: relative;

  > i.ion-ios-arrow-forward {
    color: var(--app-color-grey);
    text-align: center;
    font-size: 1.4em;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin app-card {
  position: relative;

  > i.ion-ios-arrow-forward {
    color: var(--app-color-grey);
    text-align: center;
    font-size: 1.4em;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
}

@mixin grid {
  max-width: var(--app-content-width);
  margin-left: auto;
  margin-right: auto;
}

@mixin verticalScrollBar {
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--app-color-grey-light);
  }

  &::-webkit-scrollbar {
    width: 6px;
    border-radius: 10px;
    background-color: var(--app-color-grey-light);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: var(--app-color-grey);
  }
}

@mixin user-select-disabled {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10+ and Edge */
  user-select: none; /* Standard syntax */
}

@mixin inputFocus {
  outline: none !important;
  box-shadow: var(--app-focus) !important;
}
@mixin inputNoFocus {
  outline: none !important;
  box-shadow: none !important;
}

@mixin nextInvoiceDate {
  display: flex;
  align-items: center;

  > span {
    font-weight: 500;
    margin-left: 5px;
  }

  @include is-mobile {
    flex-direction: column;
    align-items: flex-end;
  }
}

@mixin editPage-window {
  min-height: 100%;
  display: flex;
  padding: 0;

  @include is-mobile {
    display: block;
  }
}

@mixin editPage-sidePanel {
  flex: 0 1 auto;
  min-width: 280px;
  max-width: 400px;
  min-height: 100%;
  background-color: var(--app-color-flexitime-blue);
  padding: 1rem;
  display: flex;
  flex-direction: column;
  // flex-wrap: wrap;

  @include is-mobile() {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
  }

  logo-editor {
    margin: 5px auto 0;
  }
  .ph-formItem-container {
    .ph-formItem {
      .ph-formItem-value {
        color: var(--app-color-white);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        input {
          color: var(--app-color-white);
        }
      }
      @include is-mobile() {
        margin: 0;
      }
    }
  }
  .app-buttonRow {
    padding: 0 15px;
  }
}
@mixin editPage-content {
  width: 100%;
  padding: 2rem;
}
@mixin editPage-list {
  margin-bottom: 2rem;

  .ph-formItem-header {
    padding: 0 0 0.5rem;
  }
  > dp-list,
  > client-account-list,
  > client-staff-list,
  > client-note-list {
    margin-bottom: 1rem;
  }
}

@mixin loadingOpacity {
  opacity: 0.3;
}
