.app-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.25s;
  margin: 10px auto 30px auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;

  > :not(:last-child) {
    margin-bottom: 15px;
  }

  .app-footer-fromFlexitime {
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.-cursor {
      cursor: pointer;
    }

    > img {
      max-width: 130px;
      margin: 0 7px;
    }
  }
}
